﻿@import 'variables';
@import 'mixins';

$borderColor: #d4e0ec;
$borderPopupColor: #39c;
$favBackgroundColor: transparent;
$favColor: #39c;
$buttonHeight: 35px;

.WebPubElement {
    display: flex;
    width: 100%;
    overflow: hidden;
}

.WebPubElement.show-overflow {
    overflow: visible;
}

.ElementDivider {
    display: flex;
    width: 100%;
}

.ElementStandardArticle {
    width: 100%;
}

.ElementStandardArticle .textContent {
    @include toSizeSM {
        padding: 0 5px;
    }
}

/*Les mer side for standard artikkel*/
.ColumnCenter .ArticleContainer {
    @include bootstrapContainer();
    padding: 40px 0;
}

.D4Standard {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    border: 1px solid $borderColor;
    background: White;

    .InfoOverlay {
        width: 100%;
        align-self: flex-end;
        padding: 5px 0;

        @include fromSizeSM() {
            padding: 10px 0;
        }
    }

    .AddProductImage {
        width: 100%;
        position: relative;
        background: white;
    }

    .AddProductImage a img {
        max-width: 100%;
        height: auto;
        position: relative;
    }

    .AddHeaderContainer {
        margin-bottom: 8px;
    }

    .AddHeaderContainer a {
        color: inherit;
    }

    .AddHeader1,
    .AddHeader2 {
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    .AddHeaderContainer a:hover {
        text-decoration: none;
    }

    .AddHeader1 {
        font-size: 16px;
        font-weight: 300;

        @include fromSizeSM() {
            font-size: 18px;
        }
    }

    .AddHeader2 {
        font-size: 14px;
        font-weight: 300;

        @include fromSizeSM() {
            min-height: 20px;
        }
    }

    .review-score {
        margin-bottom: 5px;
    }
    /**** Prices ****/
    .AddPriceContainer {
        text-align: left;
        padding-left: 5px;
        text-align: center;

        @include fromSizeSM() {
            padding-left: 10px;
        }
    }

    .PriceLabelContainer {
        display: inline-block;
    }

    .old-price-container {
        padding-top: 5px;
        display: inline-block;
    }

    .AddPriceLabel {
        font-size: 16px;
        font-weight: bold;

        @include fromSizeSM() {
            font-size: 20px;
        }
    }

    .AddOldPriceLabel {
        text-decoration: line-through;
        white-space: nowrap;
        color: #333;
        font-size: 10px;

        @include fromSizeSM() {
            font-size: 14px;
        }
    }

    .AddPriceLabel {
        margin-right: 10px;
        color: #3399cc;
    }

    .has-discount .AddPriceLabel {
        color: #991b1f;
    }

    .veil-price-container {
        width: 100%;
        text-align: left;
        padding-left: 5px;
        line-height: 0.8em;

        @include fromSizeSM() {
            padding-left: 10px;
        }
    }
    /**** Stock ****/
    .AddStockContainer {
        position: absolute;
        right: 8px;
        bottom: 10px;
        display: none;

        @include fromSizeSM() {
            display: block;
        }
    }

    .AddStockLabel {
        display: none;
    }
    /**** Buy button ****/
    .button-container {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 9;
    }

    .preview-button,
    .fav-remove-button,
    .ad-buy-button,
    .VariantShowMore {
        background: #39c;
        float: left;
        border-radius: 0;
        border: none;
        position: relative;
        left: -1px;
        height: $buttonHeight;
        line-height: 1.4em;
    }

    .preview-button {
        background: #434345;
        color: white;

        a,
        a:hover,
        a:active {
            background: #434345;
            color: white;
            font-family: $menuFont;
        }
    }


    .fav-remove-button {
        @include fromSizeSM() {
            display: none;
        }
    }

    .ad-buy-button,
    .VariantShowMore {
        width: 50%;
        padding: 1px 1px;
        font-size: 24px;
        font-family: $menuFont;
        font-weight: 300;
        max-width: 120px;
        /*border-right: 1px solid #ccc;*/
        @include toSizeSM() {
            display: none !important;
        }
    }

    &:hover .ad-buy-button,
    &:hover .VariantShowMore,
    &:hover .preview-button {
        @include fromSizeLG {
            display: inline-block;
        }
    }

    &:hover .preview-button button {
        background: transparent;
        border: none;
    }

    &:hover .checkbox.mc-ui .cr {
        border: 2px solid $borderPopupColor;
    }

    .heart-full {
        display: none;
    }

    .favorite-button {
        border-radius: 0;
        font-size: 22px;
        padding: 0;
        width: 32px;
        z-index: 9;

        &:hover {
            padding-top: 0px;
        }

        @include toSizeSM() {
            background: $favBackgroundColor;
            color: $favColor;
        }
    }

    .fav-remove-button {
        border-radius: 0;
        font-size: 22px;
        padding: 2px 0 0 0;
        width: 32px;

        &:hover {
            font-size: 24px;
        }

        &:hover .heart-full {
            display: block;
        }

        &:hover .heart-empty {
            display: none;
        }

        @include toSizeSM() {
            display: none !important;
        }
    }

    &:hover .ad-buy-button[disabled] {
        background: #999;
        color: white;
    }
    /**** Ikoner ****/
    .YouSavePercentLabel {
        position: absolute;
        top: 30px;
        left: 5px;
        z-index: 1;
        font-weight: bold;
        background: $youSaveBackground;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: none;
        font-size: 16px;
        text-align: center;
        padding-top: 14px;

        @include fromSizeSM() {
            top: 30px;
            left: 10px;
            width: 60px;
            height: 60px;
            font-size: 22px;
            padding-top: 14px;
        }

        &::before {
            content: '-';
        }
    }

    .YouSavePercentLabel.show {
        display: block;
    }

    .AddAttributeIconContainer {
        position: absolute;
        top: -1px;
        right: -1px;
    }

    .AttributeNewIcon {
        float: right;
        background: #f26652;
        width: 40px;
        height: 25px;
        display: block;
        color: white;
        padding-top: 1px;
        text-align: center;
        font-size: 17px;
        font-family: $menuFont;
        border-radius: 0px;

        @include fromSizeSM() {
            width: 60px;
            height: 30px;
            padding-top: 2px;
            font-size: 20px;
            margin-top: 5px;
            margin-right: 5px;
        }

        &:after {
            content: 'NY!';
        }
    }

    .att-icon {
        float: right;
        clear: both;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .producer-logo {
        position: absolute;
        bottom: $buttonHeight;
        right: 0;
        display: none;
        z-index: 9;

        @include fromSizeSM() {
            display: block;
        }
    }

    .review-score {
        position: absolute;
        left: 15px;
        bottom: 0px;
        margin-bottom: 0px;
        height: 25px;
        line-height: 25px;
    }

    .BottomContainer {
        position: relative;
        height: 25px;

        .AddStockContainer {
            bottom: 0px;
            right: 15px;

            span {
                display: none;
            }
        }
    }
}

/* ImageFlip, Setting: ProductListing -> ShowSecondaryImageOnHoverInAds */
.has-secondary-image .secondary-image {
    display: none;
}

@include fromSizeSM() {
    .has-secondary-image:hover {
        .d4-prod-thumb {
            display: none;
        }

        .secondary-image {
            display: inline;
        }
    }


    .has-secondary-image.not-found:hover {
        .d4-prod-thumb {
            display: inline;
        }

        .secondary-image {
            display: none;
        }
    }
}
/* END: ImageFlip, Setting: ProductListing -> ShowSecondaryImageOnHoverInAds */

.is-orderbook {
    .D4Standard .VariantShowMore {

        &:before {
            content: "\e032";
            margin-right: 10px;
            font-size: 24px;
            position: relative;
            display: inline-block;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            margin-top: 4px;
        }

        &.choose-button-with-buy + .preview-button {
            float: right;
        }

        & + .preview-button {
            font-size: 0px;
            width: 36px;
            padding: 1px;
            left: auto;
            right: 0px;
            float: right;

            &:before {
                content: "\e086";
                margin-right: 0px;
                font-size: 24px;
                position: relative;
                display: inline-block;
                font-family: 'Glyphicons Halflings';
                font-style: normal;
                font-weight: 400;
                line-height: 1;
            }
        }
    }

    .D4Standard .VariantShowMore.choose-button-with-buy {
        left: auto;
        float: none;
        position: absolute;
        right: 36px;

        &:before {
            margin-right: 2px;
        }
    }

    .D4Standard .VariantShowMore.choose-button-with-buy, .D4Standard .VariantShowMore.choose-button-with-buy + .preview-button {
        font-size: 0px;
        width: 36px;
        padding: 1px;
    }
}

body.show-ad-checkboxes.publish-mode .check-favorite,
.check-favorite {
    display: none;
}

body.show-ad-checkboxes .ProdListContainer .check-favorite,
.pub-favorites .check-favorite {
    display: block;
    z-index: 9;
}




.checkbox.check-favorite label {
    padding-left: 0;
    position: absolute;
    top: 30px;
    left: 5px;
}



.pub-favorites .D4Standard .ad-buy-button,
.pub-favorites .D4Standard .VariantShowMore,
.pub-favorites .D4Standard .fav-remove-button {
    @include toSizeSM() {
        display: block !important;
    }

    display: block;
    z-index: 1;
}

.pub-favorites .D4Standard .favorite-button {
    @include toSizeSM() {
        display: none;
    }
}


.pub-favorites .D4Standard .YouSavePercentLabel {
    left: 28px;

    @include fromSizeSM() {
        left: 28px;
    }
}

/**** Article with background ****/
.D4Standard.ArticleWithBackground {
    justify-content: center;
}

.ArticleWithBackground {
    padding: 0;
    border: none;
    border-radius: 0;
    height: 200px;
    background: transparent;
    overflow: hidden;

    &:hover {
        cursor: initial;
    }
}

.ArticleWithBackground .inner-content,
.ArticleWithBackground .article-overlay {
    display: flex;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
}

.ArticleWithBackground .inner-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @include toSizeSM() {
        width: 100%;
    }
}

.PubAreaEditContainer .container-fluid,
.ArticleWithBackground .container-fluid {
    width: 100%;

    @include toSizeSM {
        padding: 0;
    }
}

.PubAreaEditContainer .container-fluid > div {
    @include toSizeSM {
        width: 100%;
        margin: 0;
    }
}

.ArticleWithBackground .mouse-over-effect {
    @include grow();
}

.ArticleWithBackground .heading-container {
    flex: 0 0 auto;
    max-width: 100%;

    @include fromSizeSM {
        white-space: nowrap;
    }

    font-size: 32px;
    letter-spacing: 2px;
    font-weight: bold;
    font-family: $menuFont;
    line-height: 40px;
    color: white;

    @include fromSizeSM {
        font-size: 40px;
    }
}

.ArticleWithBackground.bid-sale .heading-container {
    font-size: 28px;
    letter-spacing: 2px;
    font-weight: bold;
    font-family: $menuFont;
    line-height: 36px;
    color: white;

    @include fromSizeSM {
        font-size: 30px;
    }
}

.ArticleWithBackground .text-container {
    flex: 0 1 auto;
    text-align: left;
    color: white;
}

.ArticleWithBackground[data-link]:hover {
    cursor: pointer;
}

.article-parallax {
    background-attachment: scroll;
    /* Disable parallax for mobile and pad because it does not work well there*/
    @include fromSizeMD {
        background-attachment: fixed;
        opacity: 0;
    }
}

div.manufacturer-list {
    max-width: 1200px;
    clear: both;
}

.manufacturer-list .column {
    width: 50%;
    float: left;


    @include fromSizeMD() {
        width: 33%;
    }

    @include fromSizeLG() {
        width: 25%;
    }
}


.fullview .manufacturer-list .column {
    width: 50%;
    float: left;

    @include fromSizeSM() {
        width: 33%;
    }

    @include fromSizeMD() {
        width: 25%;
    }

    @include fromSizeLG() {
        width: 16.66666667%;
    }
}

.manufacturer-list .heading.column {
    width: 100%;
    clear: both;
}

.manufacturer-list a {
    color: $textColor;
    font-size: 16px;
    font-weight: 300;
}

.manufacturer-list a.letters {
    font-size: 28px;
}
/* Disable parallax for iOS because it does not work well there*/
.ios-detected .article-parallax {
    background-attachment: scroll;
    opacity: 1;
}

.can-animate {
    visibility: hidden;
}

.animated {
    visibility: visible;
}

.slideshow-container {
    .WebPubFieldRow {
        margin-bottom: 0;
    }

    .WebPubElement {
        margin-bottom: 0;
    }
}

.ArticleContainer {
    padding-bottom: 20px;
}
/* Make things look ok for IE9 */
.ElementStandardArticle {

    &.ArticleShowBorder {
        border: 1px solid $borderColor;
        padding: 10px;
    }

    &.ArticleRemovePadding {
        padding: 0;
    }
}

.footer-area .D4Standard .AddHeader1,
.footer-area .D4Standard .AddHeader2,
.footer-area .D4Standard .AddPriceContainer,
.footer-area .D4Standard .AddHeaderContainer {
    color: $textColor;
}
.D4Standard .AddVariantCountContainer span {
    border-radius: 5px;
    padding: 5px 10px;
    border: 1px solid #3399cc;
}

.D4-ad-bg {
    position: relative;

    .InfoOverlay {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: calc(100%);
        padding: 10px;
        background: rgba(255,255,255,0.8);
        border-top: 1px solid rgba(215,215,215,0.7);
        opacity: 1.0;
        transition: 0.4s opacity;
    }

    &.D4Standard .BottomContainer .AddStockContainer {
        right: initial;
        left: 0px;
    }

    .YouSavePercentLabel {
        background: #e0ffe0;
    }

    &:hover {
        .InfoOverlay {
            opacity: 0.0;
        }
    }
}

.D4-ad-min {
    &.D4Standard {
        border-color: #f8f8f8;

        &:hover {
            border-color: #f0f0f0;
        }

        .button-container {
            .preview-button {
                background: rgba(0,0,0,0.7);
                width: 100%;
                left: initial;
                float: none;
            }
        }

        .AddAttributeIconContainer {
        }

        .InfoOverlay {
            text-align: left;
            position: relative;

            .AddHeaderContainer {
                margin-bottom: 0px;
                padding-left: 0px;
            }

            .AddHeader1 {
                font-size: 14px;
            }

            .AddHeader2 {
                font-size: 13px;
                color: rgb(119,119,119);
            }

            .YouSavePercentLabel {
                left: initial;
                top: initial;
                position: inherit;
                width: 30px;
                height: 30px;
                font-size: 11px;
                padding-top: 8px;
                background: #e0ffe0;
                display: inline-block !important;
                margin-left: 10px;
                margin-top: -5px;
                border-radius: 0px;
            }
        }

        .AttributeNewIcon {
            background: #ffc0c0;
            font-size: 16px;
            height: 25px;
            padding: 2px;
            width: auto;
            font-family: Roboto;
        }

        .AddPriceContainer {
            text-align: center;

            .AddPriceLabel {
                font-size: 14px;
            }

            .old-price-container {
                padding-top: 0px;

                .AddOldPriceLabel {
                    position: relative;
                    text-decoration: none;

                    &:before {
                        position: absolute;
                        content: "";
                        left: 0;
                        top: 50%;
                        right: 0;
                        border-top: 1px solid;
                        border-color: inherit;
                        transform: rotate(7deg);
                    }
                }
            }
        }

        .AddStockContainer {
            position: absolute;
            right: 5px;
            bottom: 15px;
        }

        .btn.btn-default.fav-remove-button,
        .btn.btn-default.favorite-button {
            bottom: 8px;
            left: 0px;
            transition: 0.2s font-size;
            font-size: 16px;

            &:hover {
                font-size: 18px;
            }
        }
    }
}

body.img-module-ver-5 {
    .D4Standard .AddProductImage {
        margin-top: auto;
        margin-bottom: auto;
    }

    .d4-instant-search-preview-image img {
        width: auto;
        max-width: 100%;
    }

    div[data-area-id='InstantSearchProductAccessories'] div.pub-accessories img.d4-prod-thumb,
    div[data-area-id='InstantSearchProductAlternatives'] div.pub-alternatives img.d4-prod-thumb {
        width: auto;
        max-width: 100%;
    }
}
.ajax-field div[data-template-guid="071d66de-a347-43d7-8edf-ce884b68d3ec"] {
    .ProduktImg {
        min-width: 64px;
        min-height: 64px;
        max-height: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            max-width: 64px;
            height: auto;
            max-height: 64px;
            width: auto !important;
        }
    }
}

body.img-module-ver-6{
    & .WebPubElement .producer-logo img.Logo{
        max-width:35px;
    }

    & div.ProducerLogo img.ProducerLogoImage{
        max-width:50px;
    }
}